/*
Copyright 2017-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.
*/
@font-face {
  font-family: "blueprint-icons-16";
  src: url("./blueprint-icons-16.ttf?2a3c9f285f1a66764193c8cc1eb4f8e4") format("truetype"), url("./blueprint-icons-16.eot?2a3c9f285f1a66764193c8cc1eb4f8e4#iefix") format("embedded-opentype"), url("./blueprint-icons-16.woff2?2a3c9f285f1a66764193c8cc1eb4f8e4") format("woff2"), url("./blueprint-icons-16.woff?2a3c9f285f1a66764193c8cc1eb4f8e4") format("woff"), url("./blueprint-icons-16.svg?2a3c9f285f1a66764193c8cc1eb4f8e4#blueprint-icons-16") format("svg");
}
@font-face {
  font-family: "blueprint-icons-20";
  src: url("./blueprint-icons-20.ttf?1f39bca1a4a6723d6b2d83680eae2bef") format("truetype"), url("./blueprint-icons-20.eot?1f39bca1a4a6723d6b2d83680eae2bef#iefix") format("embedded-opentype"), url("./blueprint-icons-20.woff2?1f39bca1a4a6723d6b2d83680eae2bef") format("woff2"), url("./blueprint-icons-20.woff?1f39bca1a4a6723d6b2d83680eae2bef") format("woff"), url("./blueprint-icons-20.svg?1f39bca1a4a6723d6b2d83680eae2bef#blueprint-icons-20") format("svg");
}